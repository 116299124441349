import React, { useState, useEffect } from "react";
import Aside from "../../components/Aside";
import Api from "../../services/api";
import { Link, useParams } from "react-router-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import EditAcomodacao from "./edit";
import ReactTooltip from "react-tooltip";
import ReactDOM from "react-dom";
import ReactPaginate from "react-paginate";

export default function AcomodacoesList() {
  const { unidade } = useParams();
  const [acomodacao, setAcomodacao] = useState([]);
  const [unidadeInfo, setUnidadeInfo] = useState("");
  const [loading, setLoading] = useState(true);
  const Loader = () => <div className="loading"></div>;
  const [openModalEditAcomodacao, setOpenModalEditAcomodacao] = useState(false);
  const [acomodacaoEdit, setAcomodacaoEdit] = useState([]);

  const itemsPerPage = 20;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  useEffect(() => {
    getAcomodacoes();
    getUnidade();
  }, [itemOffset, itemsPerPage, unidade]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % acomodacao.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`,
    );
    setItemOffset(newOffset);
  };

  function getAcomodacoes() {
    Api.get("todasacomodacoes/" + unidade)
      .then((response) => {
        setAcomodacao(response.data);

        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(response.data.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(response.data.length / itemsPerPage));
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function getUnidade() {
    Api.get("unidades/" + unidade)
      .then((response) => {
        setUnidadeInfo(response.data[0]);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function editAcomodacao(acomodacao) {
    setAcomodacaoEdit(acomodacao);
    setOpenModalEditAcomodacao(true);
  }

  function renderTopPage() {
    return (
      <div className="top">
        <div>
          <span className="text">Acomodações / {unidadeInfo.nome}</span>
          <ul className="breadcrumbs">
            <li>Acomodações</li>
            <li>Listagem</li>
          </ul>
        </div>
        <div>
          <div className="actions">
            {/* <a href='./' className=''><i className='bx bx-plus-circle'></i> Novo Usuário</a> */}
          </div>
        </div>
      </div>
    );
  }

  function alteraAtivado(acomodacao_id, ativado) {
    setLoading(true);
    Api.get(`/acomodacao/ativada/${acomodacao_id}/${ativado}`)
      .then((response) => {
        getAcomodacoes();
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  const RenderContent = () => {
    useEffect(() => {
      ReactTooltip.rebuild();
    }, [currentItems]); // Tooltip será reconstruído ao mudar os itens

    return (
      <div className="table-container">
        <table className="custom-table">
          <thead>
            <tr>
              <th>Ações</th>
              <th>Nome</th>
            </tr>
          </thead>
          <tbody>
            {currentItems &&
              currentItems.map((acomodacao, index) => (
                <tr key={index}>
                  {/* AÇÕES */}
                  <td>
                    <Link
                      to="#"
                      data-tip="Editar"
                      data-for="tooltip-editar"
                      onClick={() => editAcomodacao(acomodacao)}
                    >
                      <i className="bx bx-edit"></i>
                    </Link>

                    <Link
                      to={`${acomodacao.id}/fotos`}
                      data-tip="Fotos"
                      data-for="tooltip-fotos"
                    >
                      <i className="bx bx-photo-album"></i>
                    </Link>

                    <a
                      href={`${process.env.REACT_APP_SITE_URL}unidades/${unidadeInfo.url}/acomodacoes/${acomodacao.url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      data-tip="Visualizar no site"
                      data-for="tooltip-visualizar"
                    >
                      <i className="bx bx-link-alt"></i>
                    </a>

                    {acomodacao.acomodacao_ativada === "1" ? (
                      <a
                        href="#"
                        className="ativado"
                        onClick={() => alteraAtivado(acomodacao.id, 0)}
                        data-tip="Clique para desativar"
                        data-for="tooltip-ativar"
                      ></a>
                    ) : (
                      <a
                        href="#"
                        className="desativado"
                        onClick={() => alteraAtivado(acomodacao.id, 1)}
                        data-tip="Clique para ativar"
                        data-for="tooltip-ativar"
                      ></a>
                    )}
                  </td>

                  {/* NOME */}
                  <td>{acomodacao.nome_pt}</td>
                </tr>
              ))}
          </tbody>
        </table>

        {/* Paginação */}
        {/* <div className="pagination">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
          />
          <div className="totalrows">
            Total de registros:{" "}
            <strong>{currentItems && currentItems.length}</strong>
          </div>
        </div> */}

        {/* ReactTooltip */}
        <ReactTooltip id="tooltip-editar" effect="solid" />
        <ReactTooltip id="tooltip-fotos" effect="solid" />
        <ReactTooltip id="tooltip-visualizar" effect="solid" />
        <ReactTooltip id="tooltip-ativar" effect="solid" />
      </div>
    );
  };
  return (
    <>
      {loading ? <Loader /> : null}
      <ReactTooltip effect="solid" />
      <div className="container-box">
        <Aside />
        <main>
          {unidadeInfo && renderTopPage()}
          {RenderContent()}
        </main>
      </div>

      <Modal
        open={openModalEditAcomodacao}
        onClose={() => {
          setOpenModalEditAcomodacao(false);
        }}
        classNames={{
          overlay: "customOverlay",
          modal: "customModal50",
        }}
      >
        <EditAcomodacao
          data={acomodacaoEdit}
          setOpenModalEditAcomodacao={setOpenModalEditAcomodacao}
          getAcomodacoes={getAcomodacoes}
        />
      </Modal>
    </>
  );
}
