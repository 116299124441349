import React, { useState, useEffect } from "react";
import Aside from "../../components/Aside";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Api from "../../services/api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";

const validationSchema = yup.object().shape({});

export default function EditSobrenos() {
  const { cidade_id } = useParams();
  const [textos, setTextos] = useState(false);
  const [loading, setLoading] = useState(false);
  const Loader = () => <div className="loading"></div>;
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitSuccessful, errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    loadTexto();
  }, [cidade_id]);

  function loadTexto() {
    //setLoading(true);
    Api.get(`/textos/sobre/${cidade_id}`)
      .then((response) => {
        setTextos(response.data);
        setLoading(false);
        console.log("response.data", response.data);

        setValue("cidade_id", cidade_id);

        // texto 1
        let texto1_filter = response.data.filter(
          (item) => item.id == (cidade_id == 1 ? 26 : 16),
        );
        setValue(
          "texto_1_pt",
          texto1_filter.map((item) => {
            return item.texto_pt;
          }),
        );

        setValue(
          "texto_1_en",
          texto1_filter.map((item) => {
            return item.texto_en;
          }),
        );

        setValue(
          "texto_1_es",
          texto1_filter.map((item) => {
            return item.texto_es;
          }),
        );

        // titulo 2
        let titulo2_filter = response.data.filter(
          (item) => item.id == (cidade_id == 1 ? 28 : 18),
        );
        setValue(
          "titulo_2_pt",
          titulo2_filter.map((item) => {
            return item.texto_pt;
          }),
        );

        setValue(
          "titulo_2_en",
          titulo2_filter.map((item) => {
            return item.texto_en;
          }),
        );

        setValue(
          "titulo_2_es",
          titulo2_filter.map((item) => {
            return item.texto_es;
          }),
        );

        // texto 2
        let texto2_filter = response.data.filter(
          (item) => item.id == (cidade_id == 1 ? 29 : 19),
        );
        setValue(
          "texto_2_pt",
          texto2_filter.map((item) => {
            return item.texto_pt;
          }),
        );

        setValue(
          "texto_2_en",
          texto2_filter.map((item) => {
            return item.texto_en;
          }),
        );

        setValue(
          "texto_2_es",
          texto2_filter.map((item) => {
            return item.texto_es;
          }),
        );

        // titulo 3
        let titulo3_filter = response.data.filter(
          (item) => item.id == (cidade_id == 1 ? 30 : 20),
        );
        setValue(
          "titulo_3_pt",
          titulo3_filter.map((item) => {
            return item.texto_pt;
          }),
        );

        setValue(
          "titulo_3_en",
          titulo3_filter.map((item) => {
            return item.texto_en;
          }),
        );

        setValue(
          "titulo_3_es",
          titulo3_filter.map((item) => {
            return item.texto_es;
          }),
        );

        // texto 3
        let texto3_filter = response.data.filter(
          (item) => item.id == (cidade_id == 1 ? 31 : 21),
        );
        setValue(
          "texto_3_pt",
          texto3_filter.map((item) => {
            return item.texto_pt;
          }),
        );

        setValue(
          "texto_3_en",
          texto3_filter.map((item) => {
            return item.texto_en;
          }),
        );

        setValue(
          "texto_3_es",
          texto3_filter.map((item) => {
            return item.texto_es;
          }),
        );

        // titulo 4
        let titulo4_filter = response.data.filter(
          (item) => item.id == (cidade_id == 1 ? 32 : 22),
        );
        setValue(
          "titulo_4_pt",
          titulo4_filter.map((item) => {
            return item.texto_pt;
          }),
        );

        setValue(
          "titulo_4_en",
          titulo4_filter.map((item) => {
            return item.texto_en;
          }),
        );

        setValue(
          "titulo_4_es",
          titulo4_filter.map((item) => {
            return item.texto_es;
          }),
        );

        // texto 4
        let texto4_filter = response.data.filter(
          (item) => item.id == (cidade_id == 1 ? 33 : 23),
        );
        setValue(
          "texto_4_pt",
          texto4_filter.map((item) => {
            return item.texto_pt;
          }),
        );

        setValue(
          "texto_4_en",
          texto4_filter.map((item) => {
            return item.texto_en;
          }),
        );

        setValue(
          "texto_4_es",
          texto4_filter.map((item) => {
            return item.texto_es;
          }),
        );

        // titulo 5
        let titulo5_filter = response.data.filter(
          (item) => item.id == (cidade_id == 1 ? 34 : 24),
        );
        setValue(
          "titulo_5_pt",
          titulo5_filter.map((item) => {
            return item.texto_pt;
          }),
        );

        setValue(
          "titulo_5_en",
          titulo5_filter.map((item) => {
            return item.texto_en;
          }),
        );

        setValue(
          "titulo_5_es",
          titulo5_filter.map((item) => {
            return item.texto_es;
          }),
        );

        // texto 5
        let texto5_filter = response.data.filter(
          (item) => item.id == (cidade_id == 1 ? 35 : 25),
        );
        setValue(
          "texto_5_pt",
          texto5_filter.map((item) => {
            return item.texto_pt;
          }),
        );

        setValue(
          "texto_5_en",
          texto5_filter.map((item) => {
            return item.texto_en;
          }),
        );

        setValue(
          "texto_5_es",
          texto5_filter.map((item) => {
            return item.texto_es;
          }),
        );
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  const onSubmit = (data) => {
    // setLoading(true);

    console.log("data", data);

    Api.post("/textos/sobre/edit", data)
      .then((response) => {
        console.log("response.data", response.data);
        if (response.data.status == "success") {
          swal("Sucesso!", response.data.message, "success");

          setLoading(false);
        } else {
          swal("Erro!", response.data.message, "error");
          setLoading(false);
        }
      })
      .catch((err) => {
        swal("Erro!", "ops! ocorreu um erro: " + err, "error");
        setLoading(false);
      });
  };

  function renderTopPage() {
    return (
      <div className="top">
        <div>
          <span className="text">
            Textos Home {cidade_id == 1 ? "Ilhabela" : "Paraty"}
          </span>
          <ul className="breadcrumbs">
            <li>Sobre nós</li>
            <li>Texto</li>
          </ul>
        </div>
      </div>
    );
  }

  function renderContent() {
    return (
      <form onSubmit={handleSubmit(onSubmit)} className="form-sobrenos">
        <Tabs>
          <TabList>
            <Tab>
              <img src={process.env.PUBLIC_URL + "/images/flag-pt.png"} />
            </Tab>
            <Tab>
              <img src={process.env.PUBLIC_URL + "/images/flag-us.png"} />
            </Tab>
            <Tab>
              <img src={process.env.PUBLIC_URL + "/images/flag-es.png"} />
            </Tab>
          </TabList>
          <TabPanel>
            <Grid container className="form">
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Titulo bloco 1</label>
                <input type="text" {...register("texto_1_pt")} />
                <span className="error">{errors.texto_1_pt?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Titulo bloco 2</label>
                <input type="text" {...register("titulo_2_pt")} />
                <span className="error">{errors.texto_2_pt?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Texto bloco 2</label>
                <textarea rows="3" {...register("texto_2_pt")}></textarea>
                <span className="error">{errors.texto_2_pt?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Titulo bloco 3</label>
                <input type="text" {...register("titulo_3_pt")} />
                <span className="error">{errors.texto_3_pt?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Texto bloco 3</label>
                <textarea rows="3" {...register("texto_3_pt")}></textarea>
                <span className="error">{errors.texto_3_pt?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Titulo bloco 4</label>
                <input type="text" {...register("titulo_4_pt")} />
                <span className="error">{errors.texto_4_pt?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Texto bloco 4</label>
                <textarea rows="3" {...register("texto_4_pt")}></textarea>
                <span className="error">{errors.texto_4_pt?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Titulo bloco 5</label>
                <input type="text" {...register("titulo_5_pt")} />
                <span className="error">{errors.texto_5_pt?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Texto bloco 5</label>
                <textarea rows="3" {...register("texto_5_pt")}></textarea>
                <span className="error">{errors.texto_5_pt?.message}</span>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel>
            <Grid container className="form">
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Titulo bloco 1</label>
                <input type="text" {...register("texto_1_en")} />
                <span className="error">{errors.texto_1_en?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Titulo bloco 2</label>
                <input type="text" {...register("titulo_2_en")} />
                <span className="error">{errors.texto_2_en?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Texto bloco 2</label>
                <textarea rows="3" {...register("texto_2_en")}></textarea>
                <span className="error">{errors.texto_2_en?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Titulo bloco 3</label>
                <input type="text" {...register("titulo_3_en")} />
                <span className="error">{errors.texto_3_en?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Texto bloco 3</label>
                <textarea rows="3" {...register("texto_3_en")}></textarea>
                <span className="error">{errors.texto_3_en?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Titulo bloco 4</label>
                <input type="text" {...register("titulo_4_en")} />
                <span className="error">{errors.texto_4_en?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Texto bloco 4</label>
                <textarea rows="3" {...register("texto_4_en")}></textarea>
                <span className="error">{errors.texto_4_en?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Titulo bloco 5</label>
                <input type="text" {...register("titulo_5_en")} />
                <span className="error">{errors.texto_5_en?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Texto bloco 5</label>
                <textarea rows="3" {...register("texto_5_en")}></textarea>
                <span className="error">{errors.texto_5_en?.message}</span>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel>
            <Grid container className="form">
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Titulo bloco 1</label>
                <input type="text" {...register("texto_1_es")} />
                <span className="error">{errors.texto_1_es?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Titulo bloco 2</label>
                <input type="text" {...register("titulo_2_es")} />
                <span className="error">{errors.texto_2_es?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Texto bloco 2</label>
                <textarea rows="3" {...register("texto_2_es")}></textarea>
                <span className="error">{errors.texto_2_es?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Titulo bloco 3</label>
                <input type="text" {...register("titulo_3_es")} />
                <span className="error">{errors.texto_3_es?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Texto bloco 3</label>
                <textarea rows="3" {...register("texto_3_es")}></textarea>
                <span className="error">{errors.texto_3_es?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Titulo bloco 4</label>
                <input type="text" {...register("titulo_4_es")} />
                <span className="error">{errors.texto_4_es?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Texto bloco 4</label>
                <textarea rows="3" {...register("texto_4_es")}></textarea>
                <span className="error">{errors.texto_4_es?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Titulo bloco 5</label>
                <input type="text" {...register("titulo_5_es")} />
                <span className="error">{errors.texto_5_es?.message}</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                <label>Texto bloco 5</label>
                <textarea rows="3" {...register("texto_5_es")}></textarea>
                <span className="error">{errors.texto_5_es?.message}</span>
              </Grid>
            </Grid>
          </TabPanel>
        </Tabs>
        <div className="actions">
          <button type="submit" className="save">
            Salvar
          </button>
        </div>
      </form>
    );
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <div className="container-box">
        <Aside />
        <main>
          {renderTopPage()}
          {textos && renderContent()}
        </main>
      </div>
    </>
  );
}
