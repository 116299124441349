import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../styles/Aside.module.css";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";

import DashboardIcon from "@mui/icons-material/Dashboard";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BedIcon from "@mui/icons-material/Bed";
import LuggageIcon from "@mui/icons-material/Luggage";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import ParkIcon from "@mui/icons-material/Park";
import DirectionsBoatFilledIcon from "@mui/icons-material/DirectionsBoatFilled";
import FlightIcon from "@mui/icons-material/Flight";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import WorkIcon from "@mui/icons-material/Work";
import FlagIcon from "@mui/icons-material/Flag";

export default function Aside() {
  const cookies = new Cookies();
  const history = useHistory();

  useEffect(() => {
    //se não estiver logado é redirecionado para a tela de login
    if (!cookies.get("hdvU")) history.push("/");
  });

  return (
    <aside className={styles.aside}>
      <img src={process.env.PUBLIC_URL + "/images/canoa.png"} />
      <nav className={styles.nav}>
        <ul className={styles.menu}>
          <li>
            <Link to="/dashboard" alt="DashBoard">
              <DashboardIcon />
            </Link>
          </li>
          {/* <li>
          <div alt="Unidades">
            <LocationOnIcon />
            <ul className={styles.submenu}>
              <li><Link to="/unidades/1" >Centro Histórico</Link></li>
              <li><Link to="/unidades/2" >Praia do Bonete</Link></li>
              <li><Link to="/unidades/3" >Vilarejo</Link></li>
            </ul>
          </div>
        </li> */}
          <li>
            <Link to="/unidades" alt="Unidades">
              <LocationOnIcon />
              <span>Unidades</span>
            </Link>
          </li>
          <li>
            <div alt="Acomodações">
              <BedIcon />
              <ul className={styles.submenu}>
                <li>
                  <Link to="/acomodacoes/1">Centro Histórico</Link>
                </li>
                <li>
                  <Link to="/acomodacoes/3">Vilarejo</Link>
                </li>
                <li>
                  <Link to="/acomodacoes/4">Albatroz</Link>
                </li>
                <li>
                  <Link to="/avaliacoes">Avaliações</Link>
                </li>
                <li>
                  <Link to="/acomodacoes/tipos">Tipos</Link>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div alt="Motor de Reservas">
              <MenuBookIcon />
              <ul className={styles.submenu}>
                <li>
                  <Link to="/reservas">Reservas</Link>
                </li>
                <li>
                  <Link to="/pacotes">Pacotes</Link>
                </li>
                <li>
                  <Link to="/motorpesquisas">Datas Pesquisadas</Link>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <Link to="/leads" alt="Leads">
              <SupervisorAccountIcon />
              <span>Leads</span>
            </Link>
          </li>
          <li>
            <div alt="Página Inicial">
              <HomeIcon />
              <ul className={styles.submenu}>
                <li>
                  <Link to="/slideshow">Slideshow</Link>
                </li>
                <li>
                  <Link to="/banners-home">Banners</Link>
                </li>
                <li>
                  <Link to="/destaques-home">Destaques</Link>
                </li>
                <li>
                  <Link to="/promocao">Banner Promocional</Link>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div alt="Textos">
              <InfoIcon />
              <ul className={styles.submenu}>
                <li>
                  <Link to="/politicaprivacidade/textos">
                    Política de Privacidade
                  </Link>
                </li>
                <li>
                  <Link to="/sustentabilidade">Sustentabilidade</Link>
                </li>
                <li>
                  <Link to="/textos/14">Expedição Paraty</Link>
                </li>
                <li>
                  <Link to="/textos/15">Expedição Ilha Grande</Link>
                </li>
                <li>
                  <strong>Home</strong>
                </li>
                <li>
                  <Link to="/textos/1">Ilhabela</Link>
                </li>
                {/* <li>
                  <Link to="/sobre-nos/fotos/1">Fotos</Link>
                </li> */}
                <li>
                  <Link to="/textos/13">Paraty e Ilha Grande</Link>
                </li>
                {/* <li>
                  <Link to="/sobre-nos/fotos/2">Fotos</Link>
                </li> */}
                <li>
                  <strong>Home Destino</strong>
                </li>
                <li>
                  <Link to="/sobre-nos/textos/1">Hostel da Vila</Link>
                </li>
                <li>
                  <Link to="/sobre-nos/textos/2">Albatroz</Link>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <Link to="/faq" alt="FAQ">
              <LiveHelpIcon />
              <span>FAQ</span>
            </Link>
          </li>
          <li>
            <div alt="Floresta Bar">
              <ParkIcon />
              <ul className={styles.submenu}>
                <li>
                  <Link to="/florestabar/textos">Texto</Link>
                </li>
                <li>
                  <Link to="/florestabar/fotos">Fotos</Link>
                </li>
                <li>
                  <Link to="/cardapio">Cardápio</Link>
                </li>
                <li>
                  <Link to="/eventos">Eventos</Link>
                </li>
                <li>
                  <Link to="/calendario-semanal">Calendário semanal</Link>
                </li>
                <li>
                  <Link to="/seu-aniversario">Faça seu aniversário</Link>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div alt="Experiências">
              <DirectionsBoatFilledIcon />
              <ul className={styles.submenu}>
                <li>
                  <Link to="/experiencias">Experiências</Link>
                </li>
                <li>
                  <Link to="/experiencias/texto">Texto</Link>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <Link to="/expedicoes" alt="Expedições">
              <FlightIcon />
              <span>Expedições</span>
            </Link>
          </li>
          <li>
            <div alt="Coworking">
              <WorkIcon />
              <ul className={styles.submenu}>
                <li>
                  <Link to="/coworking">Planos</Link>
                </li>
                <li>
                  <Link to="/coworking/texto">Texto</Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </nav>
    </aside>
  );
}
