import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "../styles/Aside.module.css";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import {
  Dashboard as DashboardIcon,
  LocationOn as LocationOnIcon,
  Bed as BedIcon,
  Home as HomeIcon,
  Info as InfoIcon,
  LiveHelp as LiveHelpIcon,
  Park as ParkIcon,
  DirectionsBoatFilled as DirectionsBoatFilledIcon,
  Flight as FlightIcon,
  SupervisorAccount as SupervisorAccountIcon,
  MenuBook as MenuBookIcon,
  Work as WorkIcon,
  Menu as MenuIcon,
  Close as CloseIcon,
} from "@mui/icons-material";

export default function Aside() {
  const cookies = new Cookies();
  const history = useHistory();
  const [menuOpened, setMenuOpened] = useState(false);

  useEffect(() => {
    if (!cookies.get("hdvU")) history.push("/");
  }, []);

  return (
    <>
      {/* Botão de menu (visível apenas no mobile) */}
      <button
        className={styles.mobileMenuBtn}
        onClick={() => setMenuOpened(true)}
      >
        <MenuIcon />
      </button>

      {/* Menu lateral */}
      <aside
        className={`${styles.aside} ${menuOpened ? styles.mobileOpen : ""}`}
      >
        {/* Botão de fechar no mobile */}
        <button
          className={styles.closeMenu}
          onClick={() => setMenuOpened(false)}
        >
          <CloseIcon />
        </button>

        <ul>
          <MenuItem
            to="/dashboard"
            icon={<DashboardIcon />}
            label="Dashboard"
          />
          <MenuItem to="/unidades" icon={<LocationOnIcon />} label="Unidades" />

          <MenuItemDropdown icon={<BedIcon />} label="Acomodações">
            <SubmenuItem to="/acomodacoes/1" label="Centro Histórico" />
            <SubmenuItem to="/acomodacoes/3" label="Vilarejo" />
            <SubmenuItem to="/acomodacoes/4" label="Albatroz" />
            <SubmenuItem to="/avaliacoes" label="Avaliações" />
            <SubmenuItem to="/acomodacoes/tipos" label="Tipos" />
          </MenuItemDropdown>

          <MenuItemDropdown icon={<MenuBookIcon />} label="Motor de Reservas">
            <SubmenuItem to="/reservas" label="Reservas" />
            <SubmenuItem to="/pacotes" label="Pacotes" />
            <SubmenuItem to="/motorpesquisas" label="Datas Pesquisadas" />
            <SubmenuItem
              to="/sincronizacao-reservas"
              label="Sincronização Cloudbeds"
            />
          </MenuItemDropdown>

          <MenuItem
            to="/leads"
            icon={<SupervisorAccountIcon />}
            label="Leads"
          />

          <MenuItemDropdown icon={<HomeIcon />} label="Página Inicial">
            <SubmenuItem to="/slideshow" label="Slideshow" />
            <SubmenuItem to="/banners-home" label="Banners" />
            <SubmenuItem to="/destaques-home" label="Destaques" />
            <SubmenuItem to="/promocao" label="Banner Promocional" />
          </MenuItemDropdown>

          <MenuItemDropdown icon={<InfoIcon />} label="Textos">
            <SubmenuItem
              to="/politicaprivacidade/textos"
              label="Política de Privacidade"
            />
            <SubmenuItem to="/sustentabilidade" label="Sustentabilidade" />
            <SubmenuItem to="/textos/14" label="Expedição Paraty" />
            <SubmenuItem to="/textos/15" label="Expedição Ilha Grande" />
            <SubmenuItem to="/textos/1" label="Ilhabela" />
            <SubmenuItem to="/textos/13" label="Paraty e Ilha Grande" />
          </MenuItemDropdown>

          <MenuItem to="/faq" icon={<LiveHelpIcon />} label="FAQ" />

          <MenuItemDropdown icon={<ParkIcon />} label="Floresta Bar">
            <SubmenuItem to="/florestabar/textos" label="Texto" />
            <SubmenuItem to="/florestabar/fotos" label="Fotos" />
            <SubmenuItem to="/cardapio" label="Cardápio" />
            <SubmenuItem to="/eventos" label="Eventos" />
            <SubmenuItem to="/calendario-semanal" label="Calendário semanal" />
            <SubmenuItem to="/seu-aniversario" label="Faça seu aniversário" />
          </MenuItemDropdown>

          <MenuItemDropdown
            icon={<DirectionsBoatFilledIcon />}
            label="Experiências"
          >
            <SubmenuItem to="/experiencias" label="Experiências" />
            <SubmenuItem to="/experiencias/texto" label="Texto" />
          </MenuItemDropdown>

          <MenuItem to="/expedicoes" icon={<FlightIcon />} label="Expedições" />

          <MenuItemDropdown icon={<WorkIcon />} label="Coworking">
            <SubmenuItem to="/coworking" label="Planos" />
            <SubmenuItem to="/coworking/texto" label="Texto" />
          </MenuItemDropdown>
        </ul>
      </aside>
    </>
  );
}

const MenuItem = ({ to, icon, label }) => (
  <li>
    <Link to={to} className={styles.menuItem}>
      {icon}
      <span>{label}</span>
    </Link>
  </li>
);

const MenuItemDropdown = ({ icon, label, children }) => {
  const [open, setOpen] = useState(false);
  return (
    <li>
      <div className={styles.menuItem} onClick={() => setOpen(!open)}>
        {icon}
        <span>{label}</span>
      </div>
      <ul className={`${styles.submenu} ${open ? styles.open : ""}`}>
        {children}
      </ul>
    </li>
  );
};

const SubmenuItem = ({ to, label }) => (
  <li>
    <Link to={to}>{label}</Link>
  </li>
);
