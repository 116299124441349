import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Aside from "../../components/Aside";
import Api from "../../services/api";
import swal from "sweetalert";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import EditSlide from "./edit";
import NewSlide from "./new";
import ReactTooltip from "react-tooltip";
import { loadCidades } from "../../helpers/functions";

export default function Destaqueshome({ darkMode, tipoLayout, tipoMenu }) {
  const [loading, setLoading] = useState(true);
  const Loader = () => <div className="loading"></div>;
  const [destaques, setDestaques] = useState([]);
  const [openModalNew, setOpenModalNew] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [slideShowEdit, setSlideShowEdit] = useState([]);
  const [cidades, setCidades] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const cidadesData = await loadCidades();
      await loadDestaqueshome();
      setCidades(cidadesData);
    }

    fetchData();
  }, []);

  function loadDestaqueshome() {
    Api.get("/destaqueshome/list")
      .then((response) => {
        setDestaques(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function editSlide(slide) {
    setSlideShowEdit(slide);
    setOpenModalEdit(true);
  }

  function deleteSlide(slide) {
    swal({
      title: "Excluir Destaque Home",
      text: "Deseja realmente excluir o destaque selecionado ?",
      icon: "warning",
      html: true,
      buttons: ["Cancelar", "Excluir"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        Api.delete("/destaqueshome/delete/" + slide.id)
          .then((response) => {
            swal(response.data.message, {
              icon: "success",
            });
            loadDestaqueshome();
            setLoading(false);
          })
          .catch((err) => {
            swal("ops! ocorreu um erro" + err, {
              icon: "success",
            });
            setLoading(false);
          });
      }
    });
  }

  const RenderContent = () => {
    useEffect(() => {
      ReactTooltip.rebuild();
    });

    const cidadesList = cidades.map((cidade, index) => {
      const rows = destaques
        .filter((destaque) => destaque.cidade_id === cidade.id)
        .map((destaque, index) => {
          const imagem = () => {
            return (
              destaque.imagem && (
                <img
                  src={process.env.REACT_APP_FILES_URL + destaque.imagem}
                  style={{ maxWidth: "100%", height: "100px" }}
                />
              )
            );
          };

          return (
            <div className="item" key={index}>
              <div>
                <a
                  href="#"
                  onClick={() => {
                    editSlide(destaque);
                  }}
                  alt="Editar"
                  data-tip="Editar"
                >
                  <i className="bx bx-edit"></i>
                </a>
                <a
                  href="#"
                  onClick={() => {
                    deleteSlide(destaque);
                  }}
                  data-tip="Excluir"
                >
                  <i className="bx bx-trash"></i>
                </a>
              </div>
              <div>{imagem()}</div>
              <div>{destaque.label_pt}</div>
            </div>
          );
        });

      return (
        <>
          <h2>{cidade.nome}</h2>
          <div className="grid">
            <div className="header">
              <div></div>
              <div>Imagem</div>
              <div>Nome</div>
            </div>
            <div className="content">{rows}</div>
          </div>
        </>
      );
    });

    return cidadesList;
  };

  function renderTopPage() {
    return (
      <div className="top">
        <div>
          <span className="text">Destaques Home</span>
          <ul className="breadcrumbs">
            <li>Destaques Home</li>
            <li>Listagem</li>
          </ul>
        </div>
        <div>
          <div className="actions top">
            <Link
              to="#"
              onClick={() => {
                setOpenModalNew(true);
              }}
            >
              <i className="bx bx-plus-circle"></i> Destaque
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <ReactTooltip effect="solid" />
      <div className="container-box">
        <Aside />
        <main>
          {renderTopPage()}
          {destaques && RenderContent()}
        </main>

        <Modal
          open={openModalNew}
          onClose={() => {
            setOpenModalNew(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal",
          }}
        >
          <NewSlide
            loadDestaqueshome={loadDestaqueshome}
            setOpenModalNew={setOpenModalNew}
          />
        </Modal>

        <Modal
          open={openModalEdit}
          onClose={() => {
            setOpenModalEdit(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal",
          }}
        >
          <EditSlide
            data={slideShowEdit}
            loadDestaqueshome={loadDestaqueshome}
            setOpenModalEdit={setOpenModalEdit}
          />
        </Modal>
      </div>
    </>
  );
}
