import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Aside from "../../components/Aside";
import Api from "../../services/api";
import swal from "sweetalert";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import moment from "moment";
import BtnStatus from "../../components/BtnStatus";
import SelectStatus from "../../components/SelectStatus";
import SelectAcomodacoesFiltro from "../../components/SelectAcomodacoesFiltro";
import SelectReservasOrigens from "../../components/SelectReservasOrigens";
import SelectUnidadesFiltro from "../../components/SelectUnidadesFiltro";
import AlteraStatus from "./alteraStatus";

import ReactTooltip from "react-tooltip";

export default function Reservas() {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalReservas, setTotalReservas] = useState(0);
  const [loading, setLoading] = useState(false);
  const Loader = () => <div className="loading"></div>;
  const [reservas, setReservas] = useState([]);
  const [search, setSearch] = useState({
    id: "",
    nome: "",
    email: "",
    checkin: "",
    checkout: "",
    status: [1, 2, 3, 4, 5],
    roomsTypes: [
      "412206",
      "61214",
      "138239",
      "412203",
      "94611",
      "10161",
      "29749",
      "412205",
      "473406",
      "226980",
      "412204",
      "415846",
      "415848",
      "415849",
      "640146",
      "642575",
      "640145",
    ],
    origens: [],
    unidades: [2033, 213236, 315853],
  });
  const [isOpenStatus, setIsOpenStatus] = useState(false);
  const [isOpenAcomodacoes, setIsOpenAcomodacoes] = useState(false);
  const [isOpenOrigens, setIsOpenOrigens] = useState(false);
  const [isOpenUnidades, setIsOpenUnidades] = useState(false);
  const [openModalStatus, setOpenModalStatus] = useState(false);
  const [reserva, setReserva] = useState([]);

  useEffect(() => {
    loadReservas();
  }, [page]);

  function loadReservas() {
    setLoading(true);

    const dataSearch = {
      idcloudbeds: search.id,
      nome: search.nome,
      email: search.email,
      status: search.status,
      roomsTypes: search.roomsTypes,
      checkin: search.checkin,
      checkout: search.checkout,
      origens: search.origens,
      unidades: search.unidades,
      pagina: page,
    };
    Api.post("/reservas/list", dataSearch)
      .then((response) => {
        setReservas(response.data.reservas);
        setTotalPages(response.data.totalpaginas);
        setTotalReservas(response.data.totalregistros);
        setLoading(false);
        console.log("response.data", response);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function cardInfo(reserva) {
    swal(
      "Informações do cartão",
      `Nome: ${reserva.cartao.cartao_nome}\n Número: ${reserva.cartao.cartao_numero}\n Mês: ${reserva.cartao.cartao_mes} \n Ano: ${reserva.cartao.cartao_ano}\n Valor: R$ ${reserva.valorAPagar}\n Parcelas: ${reserva.parcelas}x`,
    );
  }

  function iconCardInfo(reserva) {
    if (reserva.formaPagamento == 1) {
      // cartão de credito
      return (
        <a
          href="#"
          onClick={() => (reserva.status == 2 ? cardInfo(reserva) : null)}
          alt="Dados do Cartão"
          data-tip="Dados do Cartão"
        >
          <i className="bx bx-credit-card"></i>
        </a>
      );
    } else if (reserva.formaPagamento == 2) {
      // pix
      return (
        <a href="#" alt="PIX" data-tip="PIX">
          <img src={process.env.PUBLIC_URL + "/images/icon-pix.png"} />
        </a>
      );
    } else {
      return <></>;
    }
  }

  function openStatus(reserva) {
    setReserva(reserva);
    setOpenModalStatus(true);
  }

  const RenderContent = () => {
    useEffect(() => {
      ReactTooltip.rebuild();
    });

    const rows = reservas.map((reserva, index) => {
      const rooms = reserva.acomodacoes.map((room, index2) => {
        return (
          <span className="room" key={index2}>
            {room.quarto_nome}
          </span>
        );
      });

      let checkin =
        reserva.acomodacoes[0] &&
        moment(reserva.acomodacoes[0].checkin).format("DD/MM/YYYY");
      let checkout =
        reserva.acomodacoes[0] &&
        moment(reserva.acomodacoes[0].checkout).format("DD/MM/YYYY");
      let qtdnoites =
        reserva.acomodacoes[0] && reserva.acomodacoes[0].qtdnoites;

      return (
        <div className="item" key={index}>
          <div>{iconCardInfo(reserva)}</div>
          <div>{reserva.cloudbedsreservation_id}</div>
          <div>
            {reserva.titular.nome} {reserva.titular.sobrenome}
            <br />
            <small>{reserva.titular.email}</small>
          </div>
          <div>{moment(reserva.criadoEm).format("DD/MM/YYYY")}</div>
          <div>{rooms}</div>
          <div>{checkin}</div>
          <div>{checkout}</div>
          <div>{qtdnoites}</div>
          <div>R$ {reserva.valorAPagar}</div>
          <div>R$ {reserva.valorTotal}</div>
          <div>
            <BtnStatus
              status_nome={reserva.status_nome}
              status={reserva.status}
              jsoncloudbeds_error={reserva.jsoncloudbeds_error}
              onclick={() => {
                openStatus(reserva);
              }}
            />
          </div>
          <div>{reserva.origem}</div>
          <div>{reserva.unidade_nome}</div>
        </div>
      );
    });

    return (
      <>
        <div className="grid reservas">
          <div className="header">
            <div></div>
            <div>ID CloudBeds</div>
            <div>Cliente</div>
            <div>Em</div>
            <div>Acomodação</div>
            <div>Check-in</div>
            <div>Check-out</div>
            <div>Noites</div>
            <div>Valor a pagar</div>
            <div>Valor Total</div>
            <div>Status</div>
            <div>Origem</div>
            <div>Unidade</div>
          </div>
          <div className="content">{rows}</div>
        </div>
      </>
    );
  };

  const onSubmit = (event) => {
    setIsOpenStatus(false);
    setIsOpenAcomodacoes(false);
    setIsOpenOrigens(false);
    setIsOpenUnidades(false);
    setPage(1);
    loadReservas();
    event.preventDefault();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function renderFilter() {
    return (
      <div className="filter">
        <form onSubmit={onSubmit}>
          <div className="group">
            <label>ID</label>
            <input
              type="text"
              name="id"
              placeholder="id cloudbeds"
              onChange={handleChange}
            />
          </div>
          <div className="group">
            <label>Nome</label>
            <input
              type="text"
              name="nome"
              placeholder="Nome"
              onChange={handleChange}
            />
          </div>
          <div className="group">
            <label>E-mail</label>
            <input
              type="text"
              name="email"
              placeholder="E-mail"
              onChange={handleChange}
            />
          </div>
          <div className="group">
            <label>Status</label>
            <SelectStatus
              search={search}
              setSearch={setSearch}
              isOpenStatus={isOpenStatus}
              setIsOpenStatus={setIsOpenStatus}
            />
          </div>
          <div className="group">
            <label>Acomodações</label>
            <SelectAcomodacoesFiltro
              search={search}
              setSearch={setSearch}
              isOpenAcomodacoes={isOpenAcomodacoes}
              setIsOpenAcomodacoes={setIsOpenAcomodacoes}
            />
          </div>
          <div className="group">
            <label>Check-in</label>
            <input type="date" name="checkin" onChange={handleChange} />
          </div>
          <div className="group">
            <label>Check-out</label>
            <input type="date" name="checkout" onChange={handleChange} />
          </div>
          <div className="group">
            <label>Origem</label>
            <SelectReservasOrigens
              search={search}
              setSearch={setSearch}
              isOpenOrigens={isOpenOrigens}
              setIsOpenOrigens={setIsOpenOrigens}
            />
          </div>
          <div className="group">
            <label>Unidades</label>
            <SelectUnidadesFiltro
              search={search}
              setSearch={setSearch}
              isOpenUnidades={isOpenUnidades}
              setIsOpenUnidades={setIsOpenUnidades}
            />
          </div>
          <button className="send">
            <i className={"bx bx-search"}></i>
          </button>
        </form>
      </div>
    );
  }

  function nextpage() {
    if (page > totalPages) return false;

    setPage(page + 1);
    loadReservas();
    console.log("page", page);
  }

  function previouspage() {
    if (page == 1) return false;

    setPage(page - 1);
    loadReservas();
    console.log("page", page);
  }

  function renderPagination() {
    return (
      <div className="pagination2">
        <div className="total">
          Exibindo 50 registros de {totalReservas} resultados
        </div>
        <div className="pagination">
          <span className="page">Página</span>
          <span className="paginationinputs">
            <button className="left" onClick={() => previouspage()}>
              <i class="bx bx-left-arrow-alt"></i>
            </button>
            <input type="text" name="page" value={page} />
            <button className="right" onClick={() => nextpage()}>
              <i class="bx bx-right-arrow-alt"></i>
            </button>
          </span>
          <span className="totalpage">de {totalPages}</span>
        </div>
      </div>
    );
  }

  function renderTopPage() {
    return (
      <div className="top">
        <div>
          <span className="text">Reservas</span>
          <ul className="breadcrumbs">
            <li>Reservas</li>
            <li>Listagem</li>
          </ul>
        </div>
        <div>
          <div className="actions top">
            {/* <Link to="#" onClick={() => {setOpenModalNew(true)}}>
            <i className='bx bx-plus-circle'></i> Plano
          </Link> */}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <ReactTooltip effect="solid" />
      <div className="container-box">
        <Aside />
        <main>
          {renderTopPage()}
          {renderFilter()}
          {reservas && renderPagination()}
          {reservas && RenderContent()}
        </main>
      </div>

      <Modal
        open={openModalStatus}
        onClose={() => {
          setOpenModalStatus(false);
        }}
        classNames={{
          overlay: "customOverlay",
          modal: "customModal25",
        }}
      >
        <AlteraStatus
          data={reserva}
          setOpenModalStatus={setOpenModalStatus}
          loadReservas={loadReservas}
          setLoading={setLoading}
        />
      </Modal>
    </>
  );
}
