import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Aside from "../../components/Aside";
import Api from "../../services/api";
import swal from "sweetalert";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ReactTooltip from "react-tooltip";
import { loadCidades } from "../../helpers/functions";
import EditExperiencia from "./edit";
import NewExperiencia from "./new";
import ModalCategoriaNew from "./categorias/new";
import ModalCategoriaEdit from "./categorias/edit";

export default function Experiencias() {
  const [loading, setLoading] = useState(true);
  const Loader = () => <div className="loading"></div>;
  const [experiencias, setExperiencias] = useState([]);
  const [experienciasCategorias, setExperienciasCategorias] = useState([]);
  const [openModaExperiencialNew, setOpenModaExperiencialNew] = useState(false);
  const [openModalExperienciaEdit, setOpenModalExperienciaEdit] =
    useState(false);
  const [experienciasEdit, setExperienciasEdit] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [openModalCategoriaNew, setOpenModalCategoriaNew] = useState(false);
  const [openModalCategoriaEdit, setOpenModalCategoriaEdit] = useState(false);
  const [categoriaEdit, setCategoriaEdit] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const cidadesData = await loadCidades();
      await loadExperiencias();
      await loadExperienciasCategorias();
      setCidades(cidadesData);
    }

    fetchData();
  }, []);

  // useEffect(() => {
  //   loadExperiencias();
  //   loadExperienciasCategorias();
  // }, []);

  function loadExperiencias() {
    Api.get("/experiencias/list")
      .then((response) => {
        setExperiencias(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function loadExperienciasCategorias() {
    Api.get("/experiencias/categorias/list")
      .then((response) => {
        setExperienciasCategorias(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function openCategoriaEdit(categoria) {
    setCategoriaEdit(categoria);
    setOpenModalCategoriaEdit(true);
  }

  function openExperienciaEdit(experiencia) {
    setExperienciasEdit(experiencia);
    setOpenModalExperienciaEdit(true);
  }

  function deleteExperiencia(expedicao) {
    swal({
      title: "Excluir Experiência",
      text:
        "Deseja realmente excluir a experiência '" + expedicao.nome_pt + "' ?",
      icon: "warning",
      html: true,
      buttons: ["Cancelar", "Excluir"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        Api.delete("/experiencias/delete/" + expedicao.id)
          .then((response) => {
            swal(response.data.message, {
              icon: "success",
            });
            loadExperiencias();
            setLoading(false);
          })
          .catch((err) => {
            swal("ops! ocorreu um erro" + err, {
              icon: "success",
            });
            setLoading(false);
          });
      }
    });
  }

  function deleteCategoria(categoria) {
    swal({
      title: "Excluir Categoria",
      text:
        "Deseja realmente excluir a categoria '" + categoria.nome_pt + "' ?",
      icon: "warning",
      html: true,
      buttons: ["Cancelar", "Excluir"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        Api.delete("/experiencias/categorias/delete/" + categoria.id)
          .then((response) => {
            swal(response.data.message, {
              icon: "success",
            });
            loadExperienciasCategorias();
            loadExperiencias();
          })
          .catch((err) => {
            swal("ops! ocorreu um erro" + err, {
              icon: "success",
            });
          });
      }
    });
  }

  const RenderContent = () => {
    useEffect(() => {
      ReactTooltip.rebuild();
    });

    const cidadesList = cidades.map((cidade, index) => {
      const rows = experienciasCategorias
        .filter((categoria) => categoria.cidade_id === cidade.id)
        .map((categoria, index) => {
          return (
            <>
              <div className="list-default">
                <Link
                  key={index}
                  to="#"
                  data-tip="Editar"
                  onClick={() => {
                    openCategoriaEdit(categoria);
                  }}
                >
                  <h1>{categoria.nome_pt} </h1>
                </Link>
                {categoria.totalperguntas == 0 ? (
                  <Link
                    to="#"
                    data-tip="Excluir"
                    onClick={() => {
                      deleteCategoria(categoria);
                    }}
                  >
                    <i className="bx bx-trash"></i>
                  </Link>
                ) : (
                  <Link to="#"></Link>
                )}
              </div>

              <div className="grid-faq grid">
                <div className="content">
                  {experiencias
                    .filter((item) => {
                      if (item.experiencias_categorias_id == categoria.id)
                        return true;
                    })
                    .filter((item) => item.nome_cidade === cidade.nome)
                    .map((experiencia, index) => {
                      return (
                        <div className="item" key={index}>
                          <div>
                            <Link
                              to="#"
                              data-tip="Editar"
                              onClick={() => {
                                openExperienciaEdit(experiencia);
                              }}
                            >
                              <i className="bx bx-edit"></i>
                            </Link>
                            <Link
                              to={"experiencias/" + experiencia.id + "/fotos"}
                              data-tip="Fotos"
                              alt="Fotos"
                            >
                              <i className="bx bx-photo-album"></i>
                            </Link>
                            <Link
                              to="#"
                              data-tip="Excluir"
                              onClick={() => {
                                deleteExperiencia(experiencia);
                              }}
                            >
                              <i className="bx bx-trash"></i>
                            </Link>
                          </div>
                          <div>{experiencia.nome_pt}</div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </>
          );
        });

      return (
        <>
          <h2>{cidade.nome}</h2>
          <div className="grid fotos">
            <div className="content">{rows}</div>
          </div>
        </>
      );
    });

    return cidadesList;
  };

  function renderTopPage() {
    return (
      <div className="top">
        <div>
          <span className="text">Experiências</span>
          <ul className="breadcrumbs">
            <li>Experiências</li>
            <li>Listagem</li>
          </ul>
        </div>
        <div>
          <div className="actions top">
            <Link
              to="#"
              onClick={() => {
                setOpenModalCategoriaNew(true);
              }}
            >
              <i className="bx bx-plus-circle"></i> Categoria
            </Link>
            <Link
              to="#"
              onClick={() => {
                setOpenModaExperiencialNew(true);
              }}
            >
              <i className="bx bx-plus-circle"></i> Expriência
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <ReactTooltip effect="solid" />
      <div className="container-box">
        <Aside />
        <main>
          {renderTopPage()}
          {experiencias && RenderContent()}
        </main>
        =
        <Modal
          open={openModaExperiencialNew}
          onClose={() => {
            setOpenModaExperiencialNew(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal50",
          }}
        >
          <NewExperiencia
            loadExperiencias={loadExperiencias}
            loadExperienciasCategorias={loadExperienciasCategorias}
            setOpenModaExperiencialNew={setOpenModaExperiencialNew}
          />
        </Modal>
        <Modal
          open={openModalExperienciaEdit}
          onClose={() => {
            setOpenModalExperienciaEdit(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal50",
          }}
        >
          <EditExperiencia
            data={experienciasEdit}
            loadExperiencias={loadExperiencias}
            loadExperienciasCategorias={loadExperienciasCategorias}
            setOpenModalExperienciaEdit={setOpenModalExperienciaEdit}
          />
        </Modal>
        <Modal
          open={openModalCategoriaNew}
          onClose={() => {
            setOpenModalCategoriaNew(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal25",
          }}
        >
          <ModalCategoriaNew
            loadExperiencias={loadExperiencias}
            loadExperienciasCategorias={loadExperienciasCategorias}
            setOpenModalCategoriaNew={setOpenModalCategoriaNew}
          />
        </Modal>
        <Modal
          open={openModalCategoriaEdit}
          onClose={() => {
            setOpenModalCategoriaEdit(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal25",
          }}
        >
          <ModalCategoriaEdit
            data={categoriaEdit}
            setOpenModalCategoriaEdit={setOpenModalCategoriaEdit}
            loadExperiencias={loadExperiencias}
            loadExperienciasCategorias={loadExperienciasCategorias}
          />
        </Modal>
      </div>
    </>
  );
}
