import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Aside from "../../components/Aside";
import Api from "../../services/api";
import swal from "sweetalert";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ReactTooltip from "react-tooltip";

import ModalCategoriaNew from "./categorias/new";
import ModalCategoriaEdit from "./categorias/edit";
import ModalCardapioNew from "./new";
import ModalCardapioEdit from "./edit";

export default function Cardapio() {
  const [loading, setLoading] = useState(true);
  const Loader = () => <div className="loading"></div>;
  const [cardapioCategorias, setCardapioCategorias] = useState([]);
  const [cardapio, setCardapio] = useState([]);
  const [openModalCategoriaNew, setOpenModalCategoriaNew] = useState(false);
  const [openModalCategoriaEdit, setOpenModalCategoriaEdit] = useState(false);
  const [categoriaEdit, setCategoriaEdit] = useState([]);
  const [openModalCardapioNew, setOpenModalCardapioNew] = useState(false);
  const [openModalCardapioEdit, setOpenModalCardapioEdit] = useState(false);
  const [cardapioEdit, setCardapioEdit] = useState([]);

  useEffect(() => {
    loadCardapioCategorias();
    loadCardapio();
  }, []);

  function loadCardapio() {
    Api.get("/cardapio/list")
      .then((response) => {
        setCardapio(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function loadCardapioCategorias() {
    Api.get("/cardapio/categorias/list")
      .then((response) => {
        setCardapioCategorias(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function openCategoriaEdit(categoria) {
    setCategoriaEdit(categoria);
    setOpenModalCategoriaEdit(true);
  }

  function openCardapioEdit(cardapio) {
    setCardapioEdit(cardapio);
    setOpenModalCardapioEdit(true);
  }

  function deleteCardapio(cardapio) {
    swal({
      title: "Excluir Item",
      text: "Deseja realmente excluir o item '" + cardapio.nome_pt + "' ?",
      icon: "warning",
      html: true,
      buttons: ["Cancelar", "Excluir"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        Api.delete("/cardapio/delete/" + cardapio.id)
          .then((response) => {
            swal(response.data.message, {
              icon: "success",
            });
            loadCardapioCategorias();
            loadCardapio();
          })
          .catch((err) => {
            swal("ops! ocorreu um erro" + err, {
              icon: "success",
            });
          });
      }
    });
  }

  function deleteCategoria(categoria) {
    swal({
      title: "Excluir Categoria",
      text:
        "Deseja realmente excluir a categoria '" + categoria.nome_pt + "' ?",
      icon: "warning",
      html: true,
      buttons: ["Cancelar", "Excluir"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        Api.delete("/cardapio/categorias/delete/" + categoria.id)
          .then((response) => {
            swal(response.data.message, {
              icon: "success",
            });
            loadCardapioCategorias();
            loadCardapio();
          })
          .catch((err) => {
            swal("ops! ocorreu um erro" + err, {
              icon: "success",
            });
          });
      }
    });
  }

  const RenderContent = () => {
    useEffect(() => {
      ReactTooltip.rebuild();
    }, [cardapioCategorias, cardapio]); // Reconstrói os tooltips sempre que as categorias ou itens do cardápio forem alterados

    return (
      <div className="table-container">
        {cardapioCategorias.map((categoria, index) => (
          <div key={index} className="cardapio-section">
            {/* Cabeçalho da categoria */}
            <div className="cardapio-header">
              <Link
                to="#"
                data-tip="Editar"
                onClick={() => openCategoriaEdit(categoria)}
              >
                <h2>{categoria.nome_pt}</h2>
              </Link>
              {categoria.totalperguntas === 0 ? (
                <Link
                  to="#"
                  data-tip="Excluir"
                  onClick={() => deleteCategoria(categoria)}
                >
                  <i className="bx bx-trash"></i>
                </Link>
              ) : (
                <Link
                  to={`/cardapio/itens/order/${categoria.id}`}
                  data-tip="Ordenar itens dessa categoria"
                >
                  <i className="bx bx-border-all"></i>
                </Link>
              )}
            </div>

            {/* Tabela de Itens do Cardápio */}
            <table className="custom-table">
              <thead>
                <tr>
                  <th>Ações</th>
                  <th>Nome</th>
                  <th>Preço</th>
                </tr>
              </thead>
              <tbody>
                {cardapio
                  .filter(
                    (item) => item.cardapio_categorias_id === categoria.id,
                  )
                  .map((cardapioItem, index) => (
                    <tr key={index}>
                      <td>
                        <Link
                          to="#"
                          data-tip="Editar"
                          onClick={() => openCardapioEdit(cardapioItem)}
                        >
                          <i className="bx bx-edit"></i>
                        </Link>
                        <Link
                          to="#"
                          data-tip="Excluir"
                          onClick={() => deleteCardapio(cardapioItem)}
                        >
                          <i className="bx bx-trash"></i>
                        </Link>
                      </td>
                      <td>{cardapioItem.nome_pt}</td>
                      <td>R$ {cardapioItem.valor}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ))}

        {/* ReactTooltip para tooltips globais */}
        <ReactTooltip effect="solid" />
      </div>
    );
  };

  function renderTopPage() {
    return (
      <div className="top">
        <div>
          <span className="text">Cardápio</span>
          <ul className="breadcrumbs">
            <li>Cardápio</li>
            <li>Listagem</li>
          </ul>
        </div>
        <div>
          <div className="actions top">
            <Link to={`/cardapio/categories/order`}>
              <i className="bx bx-border-all"></i> Ordenar Categorias
            </Link>
            <Link
              to="#"
              onClick={() => {
                setOpenModalCategoriaNew(true);
              }}
            >
              <i className="bx bx-plus-circle"></i> Categoria
            </Link>
            <Link
              to="#"
              onClick={() => {
                setOpenModalCardapioNew(true);
              }}
            >
              <i className="bx bx-plus-circle"></i> Item
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <ReactTooltip effect="solid" />
      <div className="container-box">
        <Aside />
        <main>
          {renderTopPage()}
          <div className="container-default">
            {cardapio && cardapioCategorias && RenderContent()}
          </div>
        </main>

        <Modal
          open={openModalCategoriaNew}
          onClose={() => {
            setOpenModalCategoriaNew(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal25",
          }}
        >
          <ModalCategoriaNew
            setOpenModalCategoriaNew={setOpenModalCategoriaNew}
            loadCardapio={loadCardapio}
            loadCardapioCategorias={loadCardapioCategorias}
          />
        </Modal>

        <Modal
          open={openModalCategoriaEdit}
          onClose={() => {
            setOpenModalCategoriaEdit(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal25",
          }}
        >
          <ModalCategoriaEdit
            data={categoriaEdit}
            setOpenModalCategoriaEdit={setOpenModalCategoriaEdit}
            loadCardapio={loadCardapio}
            loadCardapioCategorias={loadCardapioCategorias}
          />
        </Modal>

        <Modal
          open={openModalCardapioNew}
          onClose={() => {
            setOpenModalCardapioNew(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal50",
          }}
        >
          <ModalCardapioNew
            setOpenModalCardapioNew={setOpenModalCardapioNew}
            loadCardapio={loadCardapio}
            loadCardapioCategorias={loadCardapioCategorias}
          />
        </Modal>

        <Modal
          open={openModalCardapioEdit}
          onClose={() => {
            setOpenModalCardapioEdit(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal50",
          }}
        >
          <ModalCardapioEdit
            data={cardapioEdit}
            setOpenModalCardapioEdit={setOpenModalCardapioEdit}
            loadCardapio={loadCardapio}
            loadCardapioCategorias={loadCardapioCategorias}
          />
        </Modal>
      </div>
    </>
  );
}
