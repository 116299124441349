import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Api from "../../services/api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import SelectCidades from "../../components/SelectCidades";

const image_formats = ["image/jpg", "image/jpeg", "image/png"];

const validationSchema = yup.object().shape({
  cidade_id: yup.string().required("Cidade obrigatório"),
  label_pt: yup.string().required("Label em português obrigatório"),
  label_en: yup.string().required("Label em inglês obrigatório"),
  label_es: yup.string().required("Label em espanhol obrigatório"),
  link: yup.string().required("Link obrigatório"),
  imagem: yup
    .mixed()
    .test(
      "fileSize",
      "Imagem maior que 1mb. Selecione uma imagem menor.",
      (file) => {
        if (file.length === 1) {
          return file[0].size <= 1000000 ? true : false;
        } else {
          return true;
        }
      },
    )
    .test(
      "FILE_FORMAT",
      "Imagem selecionada inválida. (Apenas jpg, jpeg e png)",
      (file) => {
        if (file.length === 1) {
          return image_formats.includes(file[0].type) ? true : false;
        } else {
          return true;
        }
      },
    ),
});

export default function EditBannershome({
  data,
  loadDestaqueshome,
  setOpenModalEdit,
}) {
  const [loading, setLoading] = useState(false);
  const Loader = () => <div className="loading"></div>;
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitSuccessful, errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setValue("id", data.id);
    setValue("label_pt", data.label_pt);
    setValue("label_en", data.label_en);
    setValue("label_es", data.label_es);
  }, []);

  const onSubmit = (dataDestaque) => {
    setLoading(true);
    const imagefile = document.querySelector("#imagem");

    let bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    bodyFormData.append("cidade_id", dataDestaque.cidade_id);
    bodyFormData.append("link", dataDestaque.link);
    bodyFormData.append("label_pt", dataDestaque.label_pt);
    bodyFormData.append("label_en", dataDestaque.label_en);
    bodyFormData.append("label_es", dataDestaque.label_es);
    if (imagefile.files.length > 0) {
      bodyFormData.append("imagem", imagefile.files[0]);
    }
    bodyFormData.append("oldimage", data.imagem);

    let axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    Api.post("/destaqueshome/edit", bodyFormData, axiosConfig)
      .then((response) => {
        console.log("response.data", response.data);
        if (response.data.status == "success") {
          swal("Sucesso!", response.data.message, "success");
          loadDestaqueshome();
          setOpenModalEdit(false);
          setLoading(false);
        } else {
          swal("Erro!", response.data.message, "error");
          setLoading(false);
        }
      })
      .catch((err) => {
        swal("Erro!", "ops! ocorreu um erro: " + err, "error");
        setLoading(false);
      });
  };

  const imagem = () => {
    return (
      data.imagem && (
        <img
          src={process.env.REACT_APP_FILES_URL + data.imagem}
          style={{ maxWidth: "100%" }}
        />
      )
    );
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <h2>Editar Destaque Home </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container className="form">
          <Grid item lg={12} md={6} sm={12} xs={12} className="field">
            <label>Cidade</label>
            <SelectCidades
              register={register("cidade_id")}
              selected={data.cidade_id}
            />
            <span className="error">{errors.cidade_id?.message}</span>
          </Grid>
          <Grid item lg={12} md={12} sm={12} sx={12} className="field">
            <label>Link</label>
            <input type="text" {...register("link")} defaultValue={data.link} />
            <span className="error">{errors.link?.message}</span>
          </Grid>
          <Grid item lg={12} md={12} sm={12} sx={12} className="field">
            <label>Imagem</label>
            <p>(Dimensões da imagem: 430px x 215px)</p>
            <input
              type="file"
              name="imagem"
              id="imagem"
              {...register("imagem")}
            />
            <span className="error">{errors.imagem?.message}</span>
            {imagem()}
          </Grid>

          <Grid item lg={12} md={12} sm={12} sx={12} className="field">
            <Tabs>
              <TabList>
                <Tab>
                  <img src={process.env.PUBLIC_URL + "/images/flag-pt.png"} />
                </Tab>
                <Tab>
                  <img src={process.env.PUBLIC_URL + "/images/flag-us.png"} />
                </Tab>
                <Tab>
                  <img src={process.env.PUBLIC_URL + "/images/flag-es.png"} />
                </Tab>
              </TabList>
              <TabPanel>
                <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                  <label>Nome</label>
                  <input type="text" {...register("label_pt")} />
                  <span className="error">{errors.label_pt?.message}</span>
                </Grid>
              </TabPanel>
              <TabPanel>
                <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                  <label>Nome</label>
                  <input type="text" {...register("label_en")} />
                  <span className="error">{errors.label_en?.message}</span>
                </Grid>
              </TabPanel>
              <TabPanel>
                <Grid item lg={12} md={12} sm={12} sx={12} className="field">
                  <label>Nome</label>
                  <input type="text" {...register("label_es")} />
                  <span className="error">{errors.label_es?.message}</span>
                </Grid>
              </TabPanel>
            </Tabs>
          </Grid>
        </Grid>
        <div className="actions">
          <button type="submit" className="save">
            Salvar
          </button>
          <a
            href="#"
            className="cancel"
            onClick={() => {
              setOpenModalEdit(false);
            }}
          >
            Cancelar
          </a>
        </div>
      </form>
    </>
  );
}
