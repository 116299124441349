import Api from "../services/api";

export function zeroEsquerda(val) {
  if (val < 10) {
    return "0" + val;
  } else {
    return val;
  }
}

export function criaArrayDiasMes(qtdDias) {
  let array = [];

  for (let i = 1; i <= qtdDias; i++) {
    array.push(i);
  }

  return array;
}

export async function loadCidades() {
  try {
    const response = await Api.get("/cidades/list");
    return response.data;
  } catch (err) {
    console.error("Ops! Ocorreu um erro: " + err);
    return false;
  }
}
