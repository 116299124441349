import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Aside from "../../../components/Aside";
import Api from "../../../services/api";
import moment from "moment";
import "moment/locale/pt-br";
import BtnStatusCloudbeds from "../../../components/BtnStatusCloudbeds";
import ReactTooltip from "react-tooltip";

export default function SincronizacaoCloudBedsa() {
  const [loading, setLoading] = useState(false);
  const Loader = () => <div className="loading"></div>;
  const [reservas, setReservas] = useState({
    data: [],
    reservasInexistentes: [],
  });
  const [resultsFrom, setResultsFrom] = useState(moment().format("YYYY-MM-DD"));
  const [selectedReservations, setSelectedReservations] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  // Propriedades que devem ser carregadas
  const propertyIDs = [2033, 213236, 315853];

  useEffect(() => {
    loadCloudBedsReservations(propertyIDs, resultsFrom);
  }, []);

  async function loadCloudBedsReservations(propertyIDs, date) {
    setLoading(true);

    try {
      // Criando todas as chamadas de API simultaneamente
      const requests = propertyIDs.map((propertyID) =>
        Api.post("/motorreservas/cloubBedsreservations", {
          propertyID,
          resultsFrom: date,
        }),
      );

      // Executando todas as chamadas ao mesmo tempo
      const responses = await Promise.all(requests);
      const allReservas = responses.map((res) => res.data);

      // Unindo os dados de todas as propriedades
      const mergedData = {
        data: allReservas.flatMap((res) => res.data), // Mescla todas as reservas
        reservasInexistentes: allReservas.flatMap(
          (res) => res.reservasInexistentes || [],
        ), // Mescla reservas inexistentes
        count: allReservas.reduce((acc, res) => acc + (res.count || 0), 0), // Soma o total de reservas
      };
      console.log("mergedData", mergedData);
      setReservas(mergedData);
    } catch (error) {
      console.error("Ops! Ocorreu um erro", error);
    } finally {
      setLoading(false);
    }
  }

  function handleCheckboxChange(reservationID, propertyID) {
    setSelectedReservations((prev) => {
      const exists = prev.find((item) => item.reservationID === reservationID);
      return exists
        ? prev.filter((item) => item.reservationID !== reservationID)
        : [...prev, { reservationID, propertyID }];
    });
  }

  function handleSelectAllChange() {
    if (selectAll) {
      setSelectedReservations([]);
    } else {
      const reservasInexistentes = reservas.data
        .filter((reserva) =>
          reservas.reservasInexistentes.includes(reserva.reservationID),
        )
        .map((reserva) => ({
          reservationID: reserva.reservationID,
          propertyID: reserva.propertyID,
        }));

      setSelectedReservations(reservasInexistentes);
    }
    setSelectAll(!selectAll);
  }

  function synchronizeReservations() {
    console.log("Sincronizando as reservas: ", selectedReservations);
    // Aqui pode ser chamada uma API para sincronizar as reservas selecionadas
  }

  function renderTopPage() {
    return (
      <div className="top">
        <div>
          <span className="text">
            Sincronização de Reservas Cloudbeds{" "}
            {moment(resultsFrom).format("DD/MM/YY")} ({reservas.count || 0})
          </span>
        </div>
        <div>
          <div className="actions top">
            {selectedReservations.length > 0 && (
              <button onClick={synchronizeReservations} className="sincronizar">
                Sincronizar Reservas
              </button>
            )}
            <input
              type="date"
              name="date"
              value={resultsFrom}
              onChange={(e) => {
                setResultsFrom(e.target.value);
                loadCloudBedsReservations(propertyIDs, e.target.value);
                setSelectedReservations([]);
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  function rooms(reserva) {
    return reserva.rooms && reserva.rooms.length > 0 ? (
      reserva.rooms.map((room, index) => (
        <small key={index}>{room.roomTypeName}</small>
      ))
    ) : (
      <></>
    );
  }

  const RenderContent = () => {
    useEffect(() => {
      ReactTooltip.rebuild();
    });

    return (
      <>
        <table>
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
              </th>
              <th>
                <span>Unidade</span>
              </th>
              <th>
                <span>Id cloudbeds</span>
              </th>
              <th>
                <span>Titular da reserva</span>
              </th>
              <th>
                <span>Check-in</span>
              </th>
              <th>
                <span>Check-out</span>
              </th>
              <th>
                <span>Origem</span>
              </th>
              <th>
                <span>Criada em:</span>
              </th>
              <th>
                <span>Status</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {reservas.data.map((reserva, index) => {
              const unidade =
                reserva.propertyID === "2033"
                  ? "Hostel da Vila"
                  : reserva.propertyID === "213236"
                    ? "Vilarejo Suítes"
                    : "Albatroz";

              const reservaInexistente = reservas.reservasInexistentes.includes(
                reserva.reservationID,
              );

              return (
                <tr key={index} className="item">
                  <td>
                    {reservaInexistente ? (
                      <input
                        type="checkbox"
                        value={reserva.reservationID}
                        checked={selectedReservations.some(
                          (item) =>
                            item.reservationID === reserva.reservationID,
                        )}
                        onChange={() =>
                          handleCheckboxChange(
                            reserva.reservationID,
                            reserva.propertyID,
                          )
                        }
                      />
                    ) : null}
                  </td>
                  <td>{unidade}</td>
                  <td
                    style={{
                      backgroundColor: reservaInexistente
                        ? "red"
                        : "transparent",
                    }}
                  >
                    {reserva.reservationID}
                  </td>
                  <td>
                    {reserva.guestName}
                    {/* <p>{rooms(reserva)}</p> */}
                  </td>
                  <td>{moment(reserva.startDate).format("DD/MM/YY")}</td>
                  <td>{moment(reserva.endDate).format("DD/MM/YY")}</td>
                  <td>{reserva.sourceName}</td>
                  <td>{moment(reserva.dateCreated).format("DD/MM/YY")}</td>
                  <td>
                    <BtnStatusCloudbeds
                      status_nome={reserva.status}
                      status={reserva.status}
                      jsoncloudbeds_error={reserva.jsoncloudbeds_error}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <ReactTooltip effect="solid" />
      <div className="container-box">
        <Aside />
        <main>
          {renderTopPage()}
          {reservas.data && RenderContent()}
        </main>
      </div>
    </>
  );
}
