import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Aside from "../../components/Aside";
import Api from "../../services/api";
import swal from "sweetalert";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ReactTooltip from "react-tooltip";

import ModalCategoriaNew from "./categorias/new";
import ModalCategoriaEdit from "./categorias/edit";
import ModalFaqaNew from "./new";
import ModalFaqEdit from "./edit";

export default function Faq() {
  const [loading, setLoading] = useState(true);
  const Loader = () => <div className="loading"></div>;
  const [faqCategorias, setFaqCategorias] = useState([]);
  const [faq, setFaq] = useState([]);
  const [openModalCategoriaNew, setOpenModalCategoriaNew] = useState(false);
  const [openModalCategoriaEdit, setOpenModalCategoriaEdit] = useState(false);
  const [categoriaEdit, setCategoriaEdit] = useState([]);
  const [openModalFaqNew, setOpenModalFaqNew] = useState(false);
  const [openModalFaqEdit, setOpenModalFaqEdit] = useState(false);
  const [faqEdit, setFaqEdit] = useState([]);

  useEffect(() => {
    loadFaqCategorias();
    loadFaq();
  }, []);

  function loadFaq() {
    Api.get("/faq/list")
      .then((response) => {
        setFaq(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function loadFaqCategorias() {
    Api.get("/faq/categorias/list")
      .then((response) => {
        setFaqCategorias(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function openCategoriaEdit(categoria) {
    setCategoriaEdit(categoria);
    setOpenModalCategoriaEdit(true);
  }

  function openFaqEdit(faq) {
    setFaqEdit(faq);
    setOpenModalFaqEdit(true);
  }

  function deleteFaq(faq) {
    swal({
      title: "Excluir Pergunta",
      text: "Deseja realmente excluir a pergunta '" + faq.pergunta_pt + "' ?",
      icon: "warning",
      html: true,
      buttons: ["Cancelar", "Excluir"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        Api.delete("/faq/delete/" + faq.id)
          .then((response) => {
            swal(response.data.message, {
              icon: "success",
            });
            loadFaqCategorias();
            loadFaq();
          })
          .catch((err) => {
            swal("ops! ocorreu um erro" + err, {
              icon: "success",
            });
          });
      }
    });
  }

  function deleteCategoria(categoria) {
    swal({
      title: "Excluir Tópico",
      text:
        "Deseja realmente excluir a categoria '" + categoria.nome_pt + "' ?",
      icon: "warning",
      html: true,
      buttons: ["Cancelar", "Excluir"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        Api.delete("/faq/categorias/delete/" + categoria.id)
          .then((response) => {
            swal(response.data.message, {
              icon: "success",
            });
            loadFaqCategorias();
            loadFaq();
          })
          .catch((err) => {
            swal("ops! ocorreu um erro" + err, {
              icon: "success",
            });
          });
      }
    });
  }

  const RenderContent = () => {
    useEffect(() => {
      ReactTooltip.rebuild();
    }, [faqCategorias, faq]); // Reconstrói os tooltips sempre que as categorias ou FAQ forem alterados

    return (
      <div className="table-container">
        {faqCategorias.map((categoria, index) => (
          <div key={index} className="faq-section">
            {/* Cabeçalho da categoria */}
            <div className="faq-header">
              <Link
                to="#"
                data-tip="Editar"
                onClick={() => openCategoriaEdit(categoria)}
              >
                <h2>{categoria.nome_pt}</h2>
              </Link>
              {categoria.totalperguntas === 0 && (
                <Link
                  to="#"
                  data-tip="Excluir"
                  onClick={() => deleteCategoria(categoria)}
                >
                  <i className="bx bx-trash"></i>
                </Link>
              )}
            </div>

            {/* Tabela de Perguntas */}
            <table className="custom-table">
              <thead>
                <tr>
                  <th>Ações</th>
                  <th>Pergunta</th>
                </tr>
              </thead>
              <tbody>
                {faq
                  .filter((item) => item.faqcategorias_id === categoria.id)
                  .map((faqItem, index) => (
                    <tr key={index}>
                      <td>
                        <Link
                          to="#"
                          data-tip="Editar"
                          onClick={() => openFaqEdit(faqItem)}
                        >
                          <i className="bx bx-edit"></i>
                        </Link>
                        <Link
                          to="#"
                          data-tip="Excluir"
                          onClick={() => deleteFaq(faqItem)}
                        >
                          <i className="bx bx-trash"></i>
                        </Link>
                      </td>
                      <td>{faqItem.pergunta_pt}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ))}

        {/* ReactTooltip para tooltips globais */}
        <ReactTooltip effect="solid" />
      </div>
    );
  };

  function renderTopPage() {
    return (
      <div className="top">
        <div>
          <span className="text">FAQ</span>
          <ul className="breadcrumbs">
            <li>FAQ</li>
            <li>Listagem</li>
          </ul>
        </div>
        <div>
          <div className="actions top">
            <Link
              to="#"
              onClick={() => {
                setOpenModalCategoriaNew(true);
              }}
            >
              <i className="bx bx-plus-circle"></i> Categoria
            </Link>
            <Link
              to="#"
              onClick={() => {
                setOpenModalFaqNew(true);
              }}
            >
              <i className="bx bx-plus-circle"></i> Pergunta
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <ReactTooltip effect="solid" />
      <div className="container-box">
        <Aside />
        <main>
          {renderTopPage()}
          {faq && faqCategorias && RenderContent()}
        </main>

        <Modal
          open={openModalCategoriaNew}
          onClose={() => {
            setOpenModalCategoriaNew(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal50",
          }}
        >
          <ModalCategoriaNew
            setOpenModalCategoriaNew={setOpenModalCategoriaNew}
            loadFaq={loadFaq}
            loadFaqCategorias={loadFaqCategorias}
          />
        </Modal>

        <Modal
          open={openModalCategoriaEdit}
          onClose={() => {
            openModalCategoriaEdit(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal50",
          }}
        >
          <ModalCategoriaEdit
            data={categoriaEdit}
            setOpenModalCategoriaEdit={setOpenModalCategoriaEdit}
            loadFaq={loadFaq}
            loadFaqCategorias={loadFaqCategorias}
          />
        </Modal>

        <Modal
          open={openModalFaqNew}
          onClose={() => {
            setOpenModalFaqNew(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal50",
          }}
        >
          <ModalFaqaNew
            setOpenModalFaqNew={setOpenModalFaqNew}
            loadFaq={loadFaq}
            loadFaqCategorias={loadFaqCategorias}
          />
        </Modal>

        <Modal
          open={openModalFaqEdit}
          onClose={() => {
            setOpenModalFaqEdit(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal50",
          }}
        >
          <ModalFaqEdit
            data={faqEdit}
            setOpenModalFaqEdit={setOpenModalFaqEdit}
            loadFaq={loadFaq}
            loadFaqCategorias={loadFaqCategorias}
          />
        </Modal>
      </div>
    </>
  );
}
