import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Aside from "../../components/Aside";
import Api from "../../services/api";
import swal from "sweetalert";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import EditCoworking from "./edit";
import NewCoworking from "./new";
import ReactTooltip from "react-tooltip";

export default function Coworking() {
  const [loading, setLoading] = useState(true);
  const Loader = () => <div className="loading"></div>;
  const [coworking, setCoworking] = useState([]);
  const [openModalNew, setOpenModalNew] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [coworkingEdit, setCoworkingEdit] = useState([]);

  useEffect(() => {
    loadCoworking();
  }, []);

  function loadCoworking() {
    Api.get("/coworking/list")
      .then((response) => {
        setCoworking(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  function editCoworking(expedicao) {
    setCoworkingEdit(expedicao);
    setOpenModalEdit(true);
  }

  function deleteCoworking(expedicao) {
    swal({
      title: "Excluir plano",
      text: "Deseja realmente excluir o plano '" + expedicao.nome_pt + "' ?",
      icon: "warning",
      html: true,
      buttons: ["Cancelar", "Excluir"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        Api.delete("/coworking/delete/" + expedicao.id)
          .then((response) => {
            swal(response.data.message, {
              icon: "success",
            });
            loadCoworking();
            setLoading(false);
          })
          .catch((err) => {
            swal("ops! ocorreu um erro" + err, {
              icon: "success",
            });
            setLoading(false);
          });
      }
    });
  }

  const RenderContent = () => {
    useEffect(() => {
      ReactTooltip.rebuild();
    }, [coworking]); // Reconstrói os tooltips sempre que os dados forem alterados

    return (
      <div className="table-container">
        <table className="custom-table">
          <thead>
            <tr>
              <th>Ações</th>
              <th>Nome</th>
            </tr>
          </thead>
          <tbody>
            {coworking.map((expedicao, index) => (
              <tr key={index}>
                <td>
                  <a
                    href="#"
                    onClick={() => editCoworking(expedicao)}
                    data-tip="Editar"
                    data-for="tooltip-editar"
                  >
                    <i className="bx bx-edit"></i>
                  </a>
                  <a
                    href="#"
                    onClick={() => deleteCoworking(expedicao)}
                    data-tip="Excluir"
                    data-for="tooltip-excluir"
                  >
                    <i className="bx bx-trash"></i>
                  </a>
                </td>
                <td>{expedicao.nome_pt}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* ReactTooltip para tooltips globais */}
        <ReactTooltip id="tooltip-editar" effect="solid" />
        <ReactTooltip id="tooltip-excluir" effect="solid" />
      </div>
    );
  };

  function renderTopPage() {
    return (
      <div className="top">
        <div>
          <span className="text">Coworking</span>
          <ul className="breadcrumbs">
            <li>Coworking</li>
            <li>Listagem</li>
          </ul>
        </div>
        <div>
          <div className="actions top">
            <Link
              to="#"
              onClick={() => {
                setOpenModalNew(true);
              }}
            >
              <i className="bx bx-plus-circle"></i> Plano
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <ReactTooltip effect="solid" />
      <div className="container-box">
        <Aside />
        <main>
          {renderTopPage()}
          {coworking && RenderContent()}
        </main>

        <Modal
          open={openModalNew}
          onClose={() => {
            setOpenModalNew(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal50",
          }}
        >
          <NewCoworking
            loadCoworking={loadCoworking}
            setOpenModalNew={setOpenModalNew}
          />
        </Modal>

        <Modal
          open={openModalEdit}
          onClose={() => {
            setOpenModalEdit(false);
          }}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal50",
          }}
        >
          <EditCoworking
            data={coworkingEdit}
            loadCoworking={loadCoworking}
            setOpenModalEdit={setOpenModalEdit}
          />
        </Modal>
      </div>
    </>
  );
}
