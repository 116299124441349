import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import DashBoard from "./pages/Dashboard";
import Login from "./pages/Login";
import AcomodacoesList from "./pages/acomodacoes";
import AcomodacoesFotosList from "./pages/acomodacoes/fotos";
import AcomodacoesFotosOrder from "./pages/acomodacoes/fotos/order";
import AcomodacoesTiposList from "./pages/acomodacoes/tipos";
import UnidadesList from "./pages/unidades";
import UnidadesFotosList from "./pages/unidades/fotos";
import UnidadesFotosOrder from "./pages/unidades/fotos/order";
import Pacotes from "./pages/pacotes";
import Slideshow from "./pages/slideshow";
import SlideshowOrder from "./pages/slideshow/order";
import Bannershome from "./pages/bannershome";
import Destaqueshome from "./pages/destaqueshome";
import Sobrenos from "./pages/sobrenos/edit";
import Sobrenosfotos from "./pages/sobrenos/fotos";
import FAQ from "./pages/faq";
import Expedicoes from "./pages/expedicoes";
import ExpedicoesFotosList from "./pages/expedicoes/fotos";
import Experiencias from "./pages/experiencias";
import ExperienciasTexto from "./pages/experiencias/texto";
import ExperienciasFotosList from "./pages/experiencias/fotos";
import Cardapio from "./pages/cardapio";
import CardapioCategoriasOrder from "./pages/cardapio/categorias/order";
import CardapioItensOrder from "./pages/cardapio/order";
import Eventos from "./pages/eventos";
import EventosFotosList from "./pages/eventos/fotos";
import CalendarioSemanal from "./pages/calendariosemanal";
import FacaSeuAniversario from "./pages/seuaniversaio";
import FlorestaTexto from "./pages/florestabar/edit";
import Florestafotos from "./pages/florestabar/fotos";
import Coworking from "./pages/coworking";
import CoworkingTexto from "./pages/coworking/texto";
import SustentabilidadeTexto from "./pages/sustentabilidade/texto";
import PoliticaPrivacidadeTexto from "./pages/politicaprivacidade/texto";
import Avaliacoes from "./pages/avaliacoes";
import Leads from "./pages/leads";
import Reservas from "./pages/reservas";
import SincronizacaoReservas from "./pages/reservas/sincronizacao";
import Promocao from "./pages/promocao";
import MotorPesquisas from "./pages/motorpesquisas";
import TextosEdit from "./pages/textos/edit";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/dashboard">
          <DashBoard />
        </Route>
        <Route path="/leads">
          <Leads />
        </Route>
        <Route path="/pacotes">
          <Pacotes />
        </Route>
        <Route path="/Reservas">
          <Reservas />
        </Route>
        <Route path="/sincronizacao-reservas">
          <SincronizacaoReservas />
        </Route>
        <Route path="/promocao">
          <Promocao />
        </Route>
        <Route path="/acomodacoes/tipos">
          <AcomodacoesTiposList />
        </Route>
        <Route path="/acomodacoes/:acomodacao/order">
          <AcomodacoesFotosOrder />
        </Route>
        <Route path="/acomodacoes/:acomodacao/fotos">
          <AcomodacoesFotosList />
        </Route>
        <Route path="/acomodacoes/:unidade">
          <AcomodacoesList />
        </Route>
        <Route path="/unidades/:unidade/order">
          <UnidadesFotosOrder />
        </Route>
        <Route path="/unidades/:unidade/fotos">
          <UnidadesFotosList />
        </Route>
        <Route path="/unidades">
          <UnidadesList />
        </Route>
        <Route path="/slideshow/order">
          <SlideshowOrder />
        </Route>
        <Route path="/slideshow">
          <Slideshow />
        </Route>
        <Route path="/banners-home">
          <Bannershome />
        </Route>
        <Route path="/destaques-home">
          <Destaqueshome />
        </Route>
        <Route path="/textos/:texto_id">
          <TextosEdit />
        </Route>
        <Route path="/sobre-nos/textos/:cidade_id">
          <Sobrenos />
        </Route>
        <Route path="/sobre-nos/fotos/:cidade_id">
          <Sobrenosfotos />
        </Route>
        <Route path="/florestabar/textos">
          <FlorestaTexto />
        </Route>
        <Route path="/florestabar/fotos">
          <Florestafotos />
        </Route>
        <Route path="/faq">
          <FAQ />
        </Route>
        <Route path="/cardapio/itens/order/:categoria">
          <CardapioItensOrder />
        </Route>
        <Route path="/cardapio/categories/order">
          <CardapioCategoriasOrder />
        </Route>
        <Route path="/cardapio">
          <Cardapio />
        </Route>
        <Route path="/expedicoes/:expedicao/fotos">
          <ExpedicoesFotosList />
        </Route>
        <Route path="/expedicoes">
          <Expedicoes />
        </Route>
        <Route path="/experiencias/:experiencia/fotos">
          <ExperienciasFotosList />
        </Route>
        <Route path="/experiencias/texto">
          <ExperienciasTexto />
        </Route>
        <Route path="/experiencias">
          <Experiencias />
        </Route>
        <Route path="/eventos/:evento/fotos">
          <EventosFotosList />
        </Route>
        <Route path="/eventos">
          <Eventos />
        </Route>
        <Route path="/calendario-semanal">
          <CalendarioSemanal />
        </Route>
        <Route path="/seu-aniversario">
          <FacaSeuAniversario />
        </Route>
        <Route path="/coworking/texto">
          <CoworkingTexto />
        </Route>
        <Route path="/coworking">
          <Coworking />
        </Route>
        <Route path="/avaliacoes">
          <Avaliacoes />
        </Route>
        <Route path="/sustentabilidade">
          <SustentabilidadeTexto />
        </Route>
        <Route path="/politicaprivacidade/textos">
          <PoliticaPrivacidadeTexto />
        </Route>
        <Route path="/motorpesquisas">
          <MotorPesquisas />
        </Route>

        <Route path="/">
          <Login />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
