import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Aside from "../../components/Aside";
import Api from "../../services/api";
import moment from "moment";
import "moment/locale/pt-br";

import ReactTooltip from "react-tooltip";

export default function Leads() {
  const [loading, setLoading] = useState(true);
  const Loader = () => <div className="loading"></div>;
  const [leads, setLeads] = useState([]);

  useEffect(() => {
    loadLeads();
  }, []);

  function loadLeads() {
    Api.get("/leads/list")
      .then((response) => {
        setLeads(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }

  const RenderContent = () => {
    useEffect(() => {
      ReactTooltip.rebuild();
    }, [leads]); // Reconstrói os tooltips sempre que os leads forem alterados

    return (
      <div className="table-container">
        <table className="custom-table">
          <thead>
            <tr>
              <th>Nome</th>
              <th>E-mail</th>
              <th>Telefone</th>
              <th>Interesse</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody>
            {leads.map((lead, index) => {
              let phone = lead.telefone
                .trim()
                .replace("+", "")
                .replace("(", "")
                .replace(")", "")
                .replace("-", "")
                .replace(/ /g, "")
                .replace(/_/g, "");

              let link = `https://api.whatsapp.com/send?phone=${phone}`;

              return (
                <tr key={index}>
                  <td>{lead.nome}</td>
                  <td>{lead.email}</td>
                  <td>
                    <a href={link} target="_blank" rel="noopener noreferrer">
                      {lead.telefone}
                    </a>
                  </td>
                  <td>{lead.nome_leadorigem}</td>
                  <td>{moment(lead.criadoEm).format("DD/MM/YYYY H:m:s")}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* ReactTooltip para tooltips globais */}
        <ReactTooltip effect="solid" />
      </div>
    );
  };

  function renderTopPage() {
    return (
      <div className="top">
        <div>
          <span className="text">Leads</span>
          <ul className="breadcrumbs">
            <li>Leads</li>
            <li>Listagem</li>
          </ul>
        </div>
        <div>
          <div className="actions top">
            {/* <Link to="#" onClick={() => {setOpenModalNew(true)}}>
            <i className='bx bx-plus-circle'></i> Expedição
          </Link> */}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <ReactTooltip effect="solid" />
      <div className="container-box">
        <Aside />
        <main>
          {renderTopPage()}
          {leads && RenderContent()}
        </main>
      </div>
    </>
  );
}
